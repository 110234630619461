'use strict';

// var barbaLoad = require('../../../node_modules/barba.js/');
// global.barbaLoad = barbaLoad;

// var barbaInit = require('../modules/barba_init'),
var lazyload = require('jquery-lazy'),
    lazyloadPlugin = require('../../../node_modules/jquery-lazy/jquery.lazy.plugins'),
    imgLoader = require('../modules/lazy'),
    superfishMenu = require('superfish');;

var siteGlobal = {
  initReady: function () {
    // this.barbaJsInit();
    this.fontsLoad();
    this.menuInit();
    this.activateSearch();
    this.submenuInit();
  },
  initLoad: function () {
    this.lazyLoad();
    this.linkAll();
    this.menuActiveLink();
    this.fileChangeName();
  },
  initResize: function(){
  },
  initResizeEnd: function(){
  },
  initScroll: function(){
  },
  onScroll: function(){
  },

  fontsLoad: function(){
    var fontsLoader = require('../modules/fontsLoader');
    fontsLoader();
  },
  submenuInit: function(){
    if ($(window).outerWidth() > 769) {
      $('ul.sf-menu').superfish();
    }
  },
  barbaJsInit: function(){
    barbaInit.init();
  },
  linkAll: function(){
    // $('.linkFrom').each(function() { 
    //   var elemToLink = $(this).parents('.linkTo');
    //   var pageLink = $(this).attr('href');
    //   var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
    //   if(pageLink !== undefined && pageLink!=='javascript:;') {
    //     elemToLink.addClass('pointer').click({myLink:pageLink,myTarget:linkTarget},function(e){
    //       e.preventDefault();
    //       if(e.data.myTarget==='_blank'){
    //         window.open(e.data.myLink);
    //       } else {
    //         barbaLoad.Pjax.goTo(e.data.myLink); 
    //         $("html, body").animate({ scrollTop: 0 }, 250);
    //       }
    //     });
    //   }
    // }); 
    $('.linkFrom').each(function() { 
      var elemToLink = $(this).parents('.linkTo');
      var pageLink = $(this).attr('href');
      var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink,myTarget:linkTarget},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            window.location.href = e.data.myLink;
          }
        });
      }
    }); 
  },
  lazyLoad: function(){
    imgLoader.lazyInit();
  },
  formsCheck: function(){
    const loader = $(this.container).find('.ajax-loader');
    if (!loader.length) {
      $('div.wpcf7 > form').each(function(){
        $('.wpcf7-form').wpcf7InitForm();
      });
    }
  },
  inputWrap: function(){
    $('[type="submit"]').wrap('<div class="submitWrapper button button--text"></div>');
    $('[type="file"]').after('<label><span>'+trad['file_text']+'</span></label>');

    // Aggiungo ad ogni checkbox una label per stilizzare il css custom
    
    $('[type="checkbox"]').each(function(){
      if ($(this).siblings().length) {
        return false
      } else {
        $(this).after('<label></label>');
      }
    })

    $('.referente select option:first-child').empty().text(trad['ref']);
    $('.pdfemb-zoomout, .pdfemb-zoomin').append('<span></span>');
    
  },
  fileChangeName: function(){
    $( '.inputfile' ).each( function(){
      var $input = $( this ),
        $label   = $input.next( 'label' ),
        labelVal = $label.html();

      $input.on( 'change', function(e){
        var fileName = '';

        if( this.files && this.files.length > 1 )
          fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
        else if( e.target.value )
          fileName = e.target.value.split( '\\' ).pop();

        if( fileName )
          $label.find( 'span' ).html( fileName );
        else
          $label.html( labelVal );
      });

      // Firefox bug fix
      $input.on( 'focus', function(){ $input.addClass( 'has-focus' ); }).on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
    });
  },
  menuActiveLink: function(){
    $('a').on('click', function(){
      var data_link = $(this).data('link');
      if (data_link != ''){
        $('nav li').removeClass('current_page_item');
        $('.'+data_link).addClass('current_page_item');
      }
    })

    $('.header nav a').on('click', function(){
      $('nav li').removeClass('current_page_item');
      $(this).parent().addClass('current_page_item');
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function() {
      $(this).toggleClass('open is-active');
      $('.header__menu').toggleClass('is-active');
    })
  },
  activateSearch: function(){
    $('.search-button').on('click', function(){
      $('.searchWrapper').toggleClass('activeSearch');
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
    this.inputWrap();
    this.formsCheck();
    // initializeGmapVe();
    // initializeGmapMe();
    // initializeGmapMi();
  },
};

global.siteGlobal = siteGlobal;

(function () {

  siteGlobal.initReady();

  $( window ).on('load', function() {
    siteGlobal.initLoad();
    siteGlobal.inputWrap();
    $('body, #preloader').addClass('loaded');
    // initializeGmapVe();
    // initializeGmapMe();
    // initializeGmapMi();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });


  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }

  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

  $(window).on('scroll', function(){
    siteGlobal.onScroll();
  })
}());
