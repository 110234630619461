module.exports = function (){
	// var linkCss = document.createElement("link");
	// linkCss.rel = 'stylesheet prefetch';
	// linkCss.href = 'https://fonts.googleapis.com/css?family=Abel|Abril+Fatface|Noto+Sans:400,700';
	// var siteHeadElem = document.head || document.getElementsByTagName('body')[0];
	// siteHeadElem.appendChild(linkCss);
	WebFontConfig = {
		google: { families: [ 'Abel|Abril+Fatface|Noto+Sans:400,700' ] }
	};
	(function() {
		var wf = document.createElement('script');
	    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
	    wf.type = 'text/javascript';
	    wf.async = 'true';
	    var s = document.getElementsByTagName('script')[0];
	    s.parentNode.insertBefore(wf, s);
	})();
}
